<template>
  <div class="logon">
    <BgTop></BgTop>
    <div class="logon-main">
      <div class="logon-title">登录</div>

      <div class="input-box">
        <input v-model="userName" placeholder="请输入账号" />
      </div>

      <div class="input-box input-box2">
        <input v-model="password" placeholder="请输入密码" :type="type" />
        <img
          class="eyes"
          @click="type = 'text'"
          v-if="type === 'password'"
          src="@/assets/image/close-eyes.png"
        />
        <img
          class="eyes"
          @click="type = 'password'"
          v-if="type === 'text'"
          src="@/assets/image/open-eyes.png"
        />
      </div>

      <button class="btn" @click="getLogin">登录</button>

      <div class="title-box">
        <span @click="goUrl('/mobile/register')">免费注册</span>
        <span @click="goUrl('/mobile/forgot-password')">忘记密码?</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type: "password",
      userName: "",
      password: ""
    };
  },
  methods: {
    goUrl(path) {
      this.$router.push(path);
    },
    async getLogin() {
      const res = await this.$http.get("/api/v1/Login", {
        params: {
          userName: this.userName,
          password: this.password
        }
      });

      if (res.status === 200) {
        localStorage.setItem("userid", res.data.id);

        this.$router.push("/mobile/home");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.logon {
  min-height: 100vh;
  width: 100%;
  position: relative;
  background-color: #fff;

  .logon-main {
    min-height: 100vh;
    width: 100%;
    position: relative;
    z-index: 2;
    padding: 0 38px;
    box-sizing: border-box;

    .logon-title {
      padding-top: 99px;
      text-align: center;
      color: #232323;
      font-size: 26px;
      margin-bottom: 40px;
    }

    .input-box {
      position: relative;
      width: 100%;
      margin-bottom: 20px;

      input {
        outline: none;
        border: none;
        width: 100%;
        height: 46px;
        background: rgba(255, 162, 0, 0.1);
        border-radius: 23px;
        color: #ffa200;
        padding: 0 23px;
        box-sizing: border-box;
        font-size: 14px;
      }

      input::placeholder {
        color: #cbcbcb;
      }

      .eyes {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 50%;
        transform: translateY(-50%);
        right: 23px;
      }
    }

    .input-box2 {
      input {
        padding-right: 70px;
      }
    }

    .btn {
      border: none;
      padding: 0;
      width: 100%;
      height: 46px;
      background: linear-gradient(138deg, #ffa200 0%, #ff6017 100%);
      border-radius: 23px;
      color: #ffffff;
      font-size: 18px;
      margin-top: 15px;
    }

    .title-box {
      display: flex;
      justify-content: space-between;
      color: #999999;
      font-size: 14px;
      margin-top: 25px;
    }
  }
}
</style>
